<div class="content">
  <!-- Start Content-->
  <div class="container-fluid">
      
      <div class="row">
          <!-- game start -->
          <div class="col-md-12 px-1">
              <div class="horse">
                  
                  <div class="allreport">
                      <div class="sub_path"><p>change password</p></div>
                      <div class="row mx-0 pt-2">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                              <form class="cp_ui" [formGroup]="formData" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                  <label>old password:</label>
                                  <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">
                                  <i class="mdi" (click)="toggleOldPasswordFieldTextType()" [ngClass]="{'mdi-eye': !oldPasswordTextType, 'mdi-eye-off': oldPasswordTextType }"></i>
                                  <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                    <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>new password:</label>
                                  <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">
                                  <i class="mdi" (click)="toggleNewPasswordFieldTextType()" [ngClass]="{'mdi-eye': !newPasswordTextType, 'mdi-eye-off': newPasswordTextType}"></i>
                                  <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                                    <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                                    <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least
                                        6 characters
                                    </div>
                                    <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">The
                                        New Password must
                                        contain at least: 1 uppercase letter, 1 lowercase letter, 1 number
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label>confirm password:</label>
                                  <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password_re" [ngClass]="{ 'is-invalid': submitted && f.new_password_re.errors }">
                                  <i class="mdi" (click)="toggleConfirmPasswordFieldTextType()" [ngClass]="{ 'mdi-eye': !confirmPasswordTextType, 'mdi-eye-off': confirmPasswordTextType }"></i>
                                  <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                                    <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is
                                        required</div>
                                    <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation
                                        does not match
                                    </div>
                                  </div>
                                </div>
                                <button type="submit" class="btn btn-update mt-2">update</button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- game end -->

      </div>
      <!-- end row -->
  </div> <!-- container -->
</div> <!-- content -->