import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RedirectGuard } from "./auth/guard";

const routes: Routes = [
  {path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard]},
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
