import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";

import { MessageService } from "./message/message.service";
import { SharedService } from "./services/shared.service";
import { WebserviceService } from "./services/webservice.service";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from './services/common.service';
import { RouterModule } from '@angular/router';

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LoaderComponent } from '../layout/loader/loader.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { TopnavComponent } from '../layout/topnav/topnav.component';
import { LoginComponent } from '../auth/login/login.component';
import { BetListComponent } from '../pages/sports/bet-list/bet-list.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FooterComponent } from '../layout/footer/footer.component';
import { BetSlipComponent } from '../pages/sports/bet-slip/bet-slip.component';
import { UpcomingComponent } from '../layout/upcoming/upcoming.component';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SearchDataPipe } from './pipes/search-data.pipe';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';



@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeURLPipe,
    SearchDataPipe,
    LoaderComponent,
    TopnavComponent,
    UpcomingComponent,
    LoginComponent,
    BetListComponent,
    FilterPipe,
    FooterComponent,
    BetSlipComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    TooltipModule,
    TypeaheadModule,
    SafeHtmlPipe,
    SafeURLPipe,
    LoaderComponent,
    TopnavComponent,
    LoginComponent,
    BetListComponent,
    FilterPipe,
    SearchDataPipe,
    FooterComponent,
    BetSlipComponent,
    UpcomingComponent,
    SidebarComponent
  ],
  providers: [MessageService, SharedService, WebserviceService, CommonService, { provide : ToastrService
  }],
})
export class SharedModule { }
