import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class MessageService {
  constructor(
    private toastr: ToastrService,
    public router: Router,
  ) { }

  errmessage(err: any) {
    if (err != '')
      this.toastr.error(err);
  }

  succmessage(msg: any) {
    if (msg != '')
      this.toastr.success(msg);
  }

  infomessage(msg: any) {
    if (msg != '')
      this.toastr.info(msg);
  }
}
